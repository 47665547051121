import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";

export default class QualityConfidencePage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo20.jpg" sidebarImageAlt="SPC Chart" metaTitle="Quality &amp; Confidence">
        <div id="mainContent">
          <p>
            WBA Analytical Laboratories strives to provide our customers with
            the highest quality results and services available.&nbsp;
            Experienced professionals manage our laboratories&nbsp;according to
            the guidelines of our Quality Management System, which is
            implemented across all of our laboratories.
          </p>
          <p>
            All of our laboratories are accredited to ISO 17025 by the American
            Association for Laboratory Accreditation (A2LA) and additionally, to
            be in compliance with the A2LA Food Testing Program Requirements
            which contain the "AOAC International Guidelines for Laboratories
            Performing Microbiological and Chemical Analysis of Food and
            Pharmaceuticals." Most analyses within each lab are
            accredited.&nbsp; For a complete listing, links to the A2LA scopes
            of accreditation are available&nbsp;below or at{" "}
            <a href="http://www.a2la.org" target="_blank" rel="noopener noreferrer">
              www.a2la.org.
            </a>
          </p>
          <p class="header-two">
            American Association for <br />
            Laboratory Accreditation
          </p>
          <ul>
            <li>
              Springdale, AR &ndash; Microbiology -&nbsp;
              <a
                href="http://www.a2la.org/scopepdf/1970-01.pdf?CFID=2405631&amp;CFTOKEN=c7e45e616fb0c283-5B54F4B0-1851-9E57-473FF11B8310AD21&amp;jsessionid=843010e5b9c86f53f94a2827104f601b1f4c"
                title="click to open certificate"
                target="_blank" rel="noopener noreferrer"
              >
                Cert. 1970.01
              </a>{" "}
            </li>
            <li>
              Springdale, AR &ndash; Chemistry -&nbsp;
              <a
                href="http://www.a2la.org/scopepdf/1970-02.pdf?CFID=2405631&amp;CFTOKEN=c7e45e616fb0c283-5B54F4B0-1851-9E57-473FF11B8310AD21&amp;jsessionid=843010e5b9c86f53f94a2827104f601b1f4c"
                title="click to open certificate"
                target="_blank" rel="noopener noreferrer"
              >
                Cert. 1970.02
              </a>{" "}
            </li>
            <li>
              N. Richland Hills, TX &ndash; Microbiology -&nbsp;
              <a
                href="http://www.a2la.org/scopepdf/2224-01.pdf?CFID=2405631&amp;CFTOKEN=c7e45e616fb0c283-5B54F4B0-1851-9E57-473FF11B8310AD21&amp;jsessionid=843010e5b9c86f53f94a2827104f601b1f4c"
                title="click to open certificate"
                target="_blank" rel="noopener noreferrer"
              >
                Cert. 2224.01
              </a>{" "}
            </li>
            <li>
              Pearl, MS &ndash; Microbiology -&nbsp;
              <a
                href="http://www.a2la.org/scopepdf/2646-01.pdf?CFID=2405631&amp;CFTOKEN=c7e45e616fb0c283-5B54F4B0-1851-9E57-473FF11B8310AD21&amp;jsessionid=843010e5b9c86f53f94a2827104f601b1f4c"
                title="click to open certificate"
                target="_blank" rel="noopener noreferrer"
              >
                Cert. 2646.01
              </a>{" "}
            </li>
            <li>
              River Valley, AR &ndash; Chemistry -&nbsp;
              <a
                href="http://www.a2la.org/scopepdf/2843-01.pdf?CFID=2405631&amp;CFTOKEN=c7e45e616fb0c283-5B54F4B0-1851-9E57-473FF11B8310AD21&amp;jsessionid=843010e5b9c86f53f94a2827104f601b1f4c"
                title="click to open certificate"
                target="_blank" rel="noopener noreferrer"
              >
                Cert. 2843.01
              </a>{" "}
            </li>
            <li>
              Wilkesboro, NC &ndash; Microbiology -&nbsp;
              <a
                href="http://www.a2la.org/scopepdf/2128-01.pdf?CFID=2405631&amp;CFTOKEN=c7e45e616fb0c283-5B54F4B0-1851-9E57-473FF11B8310AD21&amp;jsessionid=843010e5b9c86f53f94a2827104f601b1f4c"
                title="click to open certificate"
                target="_blank" rel="noopener noreferrer"
              >
                Cert. 2128.01
              </a>{" "}
            </li>
            <li>
              Wilkesboro, NC - Chemistry -&nbsp;
              <a
                href="http://www.a2la.org/scopepdf/2128-02.pdf?CFID=2405631&amp;CFTOKEN=c7e45e616fb0c283-5B54F4B0-1851-9E57-473FF11B8310AD21&amp;jsessionid=843010e5b9c86f53f94a2827104f601b1f4c"
                title="click to open certificate"
                target="_blank" rel="noopener noreferrer"
              >
                Cert. 2128.02
              </a>{" "}
            </li>
          </ul>
          <p>
            The Quality Management System was
            initially created in the Springdale location and is&nbsp;currently
            identically implemented in all six laboratories.
          </p>
          <p>
            <a href="http://www.a2la.org/" target="_blank" rel="noopener noreferrer">
              <img
                alt="Accredidations All locations"
                src="/images/accredidations_alllocations.jpg"
              />
            </a>
            <strong>
              <a
                /*href="/documents/tuv_certificate_2011.pdf"
                target="_blank" rel="noopener noreferrer"*/
              >
                <img
                  height="119"
                  width="109"
                  alt="Accrediation Springdale"
                  src="/images/accredidations_springdale.png"
                />
              </a>
              &nbsp;&nbsp;{" "}
              <a href="http://www.arkansas-quality.org/" target="_blank" rel="noopener noreferrer">
                <img alt="GQA 2010" src="/images/gqa_2010_logo.jpg" />
              </a>
            </strong>
          </p>
          <strong>
            <map
              name="rade_img_map_1309269001254"
              id="rade_img_map_1309269001254"
            >
              <area
                href="http://www.a2la.org"
                shape="rect"
                coords="0,0,134,130"
                alt="AZLA"
              />
              <area
                href="http://tuvamerica.com/newhome.cfm"
                shape="rect"
                coords="134,0,259,130"
                alt="TUV"
              />
              <area
                href="http://www.arkansas-quality.org"
                shape="rect"
                coords="259,0,400,130"
                alt="Governor's Quality Award"
              />
            </map>
          </strong>
          <p>
            WBA Analytical Laboratories&nbsp;Quality Management System is also
            based on the Baldrige Criteria for Performance Excellence for which
            the Springdale, Arkansas, facility was the 2010 recipient of the
            Governor&rsquo;s Quality Award for the Arkansas Baldrige program.
          </p>
          <div class="header-one">Other Accreditations &amp; Approvals</div>
          <div class="header-two">
            <strong>USDA-FSIS</strong>
          </div>
          <ul>
            <li>Food Chemistry Analysis </li>
            <li>Pesticide Residue Analysis (CHC and PCB) </li>
          </ul>
          <div class="header-two">
            <strong>
              AMS Laboratory Verification Program for the Analysis of Poultry
              Products Destined for Exportation from the United States to Russia
            </strong>
          </div>
          <ul>
            <li>Pesticide Residues in Poultry </li>
            <li>Tetracyclines in Poultry </li>
            <li>
              Salmonella sp., Listeria monocytogenes and Total Plate Count{" "}
            </li>
          </ul>
          <div class="header-two">
            <strong>USDA-APHIS-NPIP </strong>
          </div>
          <ul>
            <li>Approved for Avian Influenza AGID Analysis </li>
            <li>
              APHIS EC Regulation 1774/2002 Compliance (AR-LAB-001 and
              AR-LAB-003){" "}
            </li>
          </ul>
          <div class="header-two">
            <strong>Wastewater Laboratory Approvals</strong>
          </div>
          <ul>
            <li>Arkansas Department of Environmental Quality </li>
            <li>Oklahoma Department of Environmental Quality </li>
          </ul>
          <div class="header-one">Proficiency Programs</div>
          <p>
            WBA Analytical Laboratories<strong> </strong>participate in a wide
            array of proficiency programs as an additional validation of the
            work performed within our laboratories.&nbsp; Where available, all
            tests performed within our laboratories are covered by commercial
            proficiency programs at least annually.&nbsp; Where commercial
            programs are not available, proficiency is evaluated using other
            means such as split samples with other laboratories or through the
            purchase of acceptable known value materials.&nbsp; Below is a list
            of commercial proficiency programs currently utilized by WBA
            Analytical Laboratories.
          </p>
          <div class="header-two">Chemistry Proficiency Programs</div>
          <ul>
            <li>AOAC Food Chemistry Program </li>
            <li>USDA Food Chemistry Program </li>
            <li>USDA CHCs in Meat and Poultry Products </li>
            <li>USDA PCBs in Meat and Poultry Products </li>
            <li>AAFCO for Feeds &amp; Ingredients </li>
            <li>AACC </li>
            <li>AOCS </li>
            <li>Canadian Program for Antibiotic Residues </li>
            <li>USDA/AMS Russian Export Program </li>
            <li>Environmental Resource Associates </li>
          </ul>
          <div class="header-two">Microbiology Programs</div>
          <ul>
            <li>AOAC Food Microbiology </li>
            <li>USDA/AMS Russian Export Program </li>
            <li>NPIP Salmonella Group D </li>
          </ul>
        </div>

        <RightColumn />
      </Layout>
    );
  }
}
